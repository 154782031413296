import {createSlice, PayloadAction, current} from "@reduxjs/toolkit";

import {
    PretripList,
    PretripCardList,
    PreTripUserStatistic,
    PreTripUsersLastCard,
    PreTripUpdateStatus,
    PreTripUpdateComment,
    PretripDriverCardList, PretripCard, PretripDriverCard,
} from "@app-types/preTrip.model";

import {
    getPreTripList,
    getPreTripCards,
    getUsersLastCardById,
    getPreTripDriverCards,
    sendStatus,
    sendComment,
    getUserStatistic,
} from "./thunks";


type PretripState = {
    preTripList: PretripList | null;
    medicalCards: PretripCardList | null;
    driverCards: PretripDriverCardList | null;
    userStatistic: PreTripUserStatistic | null;
    cardById: PreTripUsersLastCard | null;
    loadingStatus: boolean;
};

const initialState: PretripState = {
    preTripList: null,
    medicalCards: null,
    driverCards: null,
    userStatistic: null,
    cardById: null,
    loadingStatus: false,
};

const preTripSlice = createSlice({
    name: "preTrip",
    initialState,
    reducers: {
        resetPreTripSlice: () => initialState,

        updateStatus: (state, action: PayloadAction<PreTripUpdateStatus>) => {
            let id = action.payload.id;
            let status = action.payload.status;
            let history = action.payload.history;

            state.medicalCards?.records.forEach((record) => {
                if (record.id === id) {
                    record.status = status!;
                    if (history && record.history) record.history.push(history);
                    if (history && !record.history) record.history = [history];
                }
            });

            state.driverCards?.records.forEach((record) => {
                if (record.id === id) {
                    record.status = status!;
                    if (history) record.history.statusChange = history;
                }
            });
        },

        updateComment: (state, action: PayloadAction<PreTripUpdateComment>) => {
            let id = action.payload.id;
            let comment = action.payload.comment;
            let history = action.payload.history;

            state.medicalCards?.records.forEach((record) => {
                if (record.id === id) {
                    if (comment) record.doctorsRecommendation = comment;
                    if (history && record.history) record.history.push(history);
                    if (history && !record.history) record.history = [history];
                }
            });

            state.driverCards?.records.forEach((record) => {
                if (record.id === id) {
                    if (comment) record.doctorsRecommendation = comment;
                    if (history) record.history.commentChange = history;
                }
            });
        },

        updateMedicalCards: (state, action: PayloadAction<PretripCard>) => {
            const record = action.payload

            if (state.medicalCards) {
                state.medicalCards.records = [record, ...state.medicalCards.records];
            }
        },

        updateDriverCards: (
            state,
            action: PayloadAction<PretripDriverCard>
        ) => {
            const record = action.payload

            if (state.driverCards) {
                state.driverCards.records = [record, ...state.driverCards.records];
            }
        },
    },
    extraReducers: (builder) => {
        // =======================PreTrip===========================
        builder
            .addCase(getPreTripList.pending, (state, _) => {
                state.loadingStatus = true;
            })
            .addCase(getPreTripList.fulfilled, (state, action) => {
                state.preTripList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getPreTripList.rejected, (state, _) => {
                state.preTripList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getPreTripCards.pending, (state, _) => {
                state.loadingStatus = true;
            })
            .addCase(getPreTripCards.fulfilled, (state, action) => {
                state.medicalCards = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getPreTripCards.rejected, (state, _) => {
                state.medicalCards = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getPreTripDriverCards.pending, (state, _) => {
                state.loadingStatus = true;
            })
            .addCase(getPreTripDriverCards.fulfilled, (state, action) => {
                state.driverCards = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getPreTripDriverCards.rejected, (state, _) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getUsersLastCardById.pending, (state, _) => {
                state.loadingStatus = true;
            })
            .addCase(getUsersLastCardById.fulfilled, (state, action) => {
                state.cardById = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getUsersLastCardById.rejected, (state, _) => {
                state.loadingStatus = false;
            });
        // =======================PreTripHub=====================
        builder
            .addCase(sendStatus.pending, (state, _) => {
            })
            .addCase(sendStatus.fulfilled, (state, action) => {
            })
            .addCase(sendStatus.rejected, (state, _) => {
            });
        // ========================================================
        builder
            .addCase(sendComment.pending, (state, _) => {
            })
            .addCase(sendComment.fulfilled, (state, action) => {
            })
            .addCase(sendComment.rejected, (state, _) => {
            });
        // ======================Statistic=======================
        builder
            .addCase(getUserStatistic.pending, (state, _) => {
                state.loadingStatus = true;
            })
            .addCase(getUserStatistic.fulfilled, (state, action) => {
                state.userStatistic = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getUserStatistic.rejected, (state, _) => {
                state.loadingStatus = false;
            });
        // ========================================================
    },
});

const {reducer, actions} = preTripSlice;
export const {
    resetPreTripSlice,
    updateStatus,
    updateComment,
    updateMedicalCards,
    updateDriverCards,
} = actions;

export default reducer;
