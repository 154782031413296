import {Middleware, MiddlewareAPI} from "@reduxjs/toolkit";

import {connectionStart, connectionStop} from "../slices/hub/hubSlice";


import {HUB_SIGNAL_R} from "@constants/api/pretrip_api";
import {updateComment, updateDriverCards, updateMedicalCards, updateStatus} from "@redux/slices/preTrip/preTripSlice";
import {PretripCard, PretripCardList, PretripDriverCard, PretripDriverCardList} from "@app-types/preTrip.model";


const hubMiddleware: Middleware = (store: MiddlewareAPI) => {
    let ws: WebSocket;

    return (next) => (action) => {
        let state = store.getState();
        let id = null;
        let role = "";
        let url = null;

        const {
            profile: {profileInfo},
        } = state;

        id = profileInfo?.id;
        role = profileInfo?.role;
        url = `${HUB_SIGNAL_R}/${id}`;

        if (connectionStart.match(action)) {
            try {

                ws = new WebSocket(url);

                ws.onopen = () => {
                    console.log("WS connection started!")
                }

                ws.onmessage = (e) => {
                    const msg = JSON.parse(e.data);
                    const event = msg.event;


                    switch (event) {
                        case "SendNewCardsNotice":
                            if (role === "medicalworker" || role === "supervisor") {
                                store.dispatch(updateMedicalCards(msg as PretripCard));
                            } else if (role === "driver") {
                                store.dispatch(updateDriverCards(msg as PretripDriverCard));
                            }
                            break;
                        case "SendCommentChangeNotice":
                            store.dispatch(
                                updateComment({
                                    id: msg.id,
                                    comment: msg.comment,
                                    history: msg.history,
                                })
                            );
                            break;
                        case "SendStatusChangeNotice":
                            store.dispatch(
                                updateStatus({id: msg.id, status: msg.status, history: msg.history})
                            );
                            break
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (connectionStop.match(action)) {
            try {

                ws.onclose = () => {
                    console.log("WS connection stopped!")
                }
            } catch (e) {
                console.log(e);
            }
        }

        next(action);
    };
};

export default hubMiddleware;
