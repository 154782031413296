import {createSlice} from "@reduxjs/toolkit";
import {TableInfo, TranslatedRoles} from "@constants/roles";

import {
    AllUsersAdminPanelList,
    UsersEntityList,
    DriverUsersOrgPanelList,
    CompainesList,
} from "@app-types/users.model";

import {
    editAdminPanelUser,
    editOrgUsers,
    addNewUserAdminPanel,
    getAdmins,
    getUsers,
    getCompanyList,
    deleteCompany,
    createCompany,
    getJobsTitlesAdmin,
    getJobsTitlesSupervisor,
    createJobTitle,
    editJobTitle,
    getDriversOrgPanel,
    getDriversAdminPanel,
    getMedicalOrgPanel,
    getMedicalAdminPanel,
    getDispatcherOrgPanel,
    getDispatcherAdminPanel,
    getRoles,
    getWorkers,
    getSupervisors,
    addNewUserOrgPanel,
    deleteOrgUser,
    deleteUserAdminPanel,
    deleteJobTitle,
    editCompany,
    getCompanies,
} from "./thunks";

type UsersState = {
    loadingStatus: boolean;
    roleList: FoundationApp.SelectItem[] | null;
    usersList: AllUsersAdminPanelList | null;
    driversList: DriverUsersOrgPanelList | UsersEntityList | null;
    dispatcherList: UsersEntityList | null;
    adminList: UsersEntityList | null;
    medicalList: UsersEntityList | null;
    supervisorList: UsersEntityList | null;
    compaines: CompainesList | null;
    companyList: FoundationApp.SelectItem[] | null;
    jobsList: FoundationApp.SelectItem[] | null;
    workersList: FoundationApp.SelectItem[] | null;
};

const initialState: UsersState = {
    loadingStatus: false,
    roleList: null,
    usersList: null,
    driversList: null,
    dispatcherList: null,
    adminList: null,
    medicalList: null,
    supervisorList: null,
    compaines: null,
    companyList: null,
    jobsList: null,
    workersList: null,
};

const updateState = (
    tableInfo: string,
    state: UsersState,
    data: UsersEntityList | DriverUsersOrgPanelList
) => {
    switch (tableInfo) {
        case TableInfo.Admin:
            state.adminList = data;
            break;
        case TableInfo.Supervisor:
            state.supervisorList = data;
            break;
        case TableInfo.DriverAdmin:
            state.driversList = data;
            break;
        case TableInfo.DriverOrg:
            state.driversList = data as DriverUsersOrgPanelList;
            break;
        case TableInfo.MedicalOrg:
            state.medicalList = data;
            break;
        case TableInfo.MedicalAdmin:
            state.medicalList = data;
            break;
        case TableInfo.DispatcherAdmin:
            state.dispatcherList = data;
            break;
        case TableInfo.DispatcherOrg:
            state.dispatcherList = data;
            break;
    }
};

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetUsersSlice: () => initialState,
    },
    extraReducers: (builder) => {
        // ========================================================
        builder
            .addCase(getAdmins.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getAdmins.fulfilled, (state, action) => {
                state.adminList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getAdmins.rejected, (state, action) => {
                state.adminList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getUsers.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.usersList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.usersList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(editAdminPanelUser.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(editAdminPanelUser.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState as UsersEntityList;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(editAdminPanelUser.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(addNewUserAdminPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(addNewUserAdminPanel.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(addNewUserAdminPanel.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(deleteUserAdminPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(deleteUserAdminPanel.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState as UsersEntityList;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(deleteUserAdminPanel.rejected, (state, action) => {
                state.supervisorList = null;
                state.adminList = null;
                state.driversList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getCompanies.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.compaines = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getCompanies.rejected, (state, action) => {
                state.companyList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(createCompany.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(createCompany.fulfilled, (state, action) => {
                state.compaines = action.payload;
                state.loadingStatus = false;
            })
            .addCase(createCompany.rejected, (state, action) => {
                state.compaines = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(editCompany.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(editCompany.fulfilled, (state, action) => {
                state.compaines = action.payload;
                state.loadingStatus = false;
            })
            .addCase(editCompany.rejected, (state, action) => {
                state.compaines = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(deleteCompany.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(deleteCompany.fulfilled, (state, action) => {
                state.compaines = action.payload;
                state.loadingStatus = false;
            })
            .addCase(deleteCompany.rejected, (state, action) => {
                state.compaines = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getCompanyList.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getCompanyList.rejected, (state, action) => {
                state.companyList = null;
                state.loadingStatus = false;
            });
        // ========================================================

        builder
            .addCase(getJobsTitlesAdmin.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getJobsTitlesAdmin.fulfilled, (state, action) => {
                state.jobsList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getJobsTitlesAdmin.rejected, (state, action) => {
                state.jobsList = null;
                state.loadingStatus = false;
            });// ========================================================

        builder
            .addCase(getJobsTitlesSupervisor.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getJobsTitlesSupervisor.fulfilled, (state, action) => {
                state.jobsList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getJobsTitlesSupervisor.rejected, (state, action) => {
                state.jobsList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(createJobTitle.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(createJobTitle.fulfilled, (state, action) => {
                state.jobsList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(createJobTitle.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(editJobTitle.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(editJobTitle.fulfilled, (state, action) => {
                state.jobsList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(editJobTitle.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(deleteJobTitle.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(deleteJobTitle.fulfilled, (state, action) => {
                state.jobsList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(deleteJobTitle.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getDriversOrgPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getDriversOrgPanel.fulfilled, (state, action) => {
                state.driversList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getDriversOrgPanel.rejected, (state, action) => {
                state.driversList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getDispatcherOrgPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getDispatcherOrgPanel.fulfilled, (state, action) => {
                state.dispatcherList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getDispatcherOrgPanel.rejected, (state, action) => {
                state.dispatcherList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getDispatcherAdminPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getDispatcherAdminPanel.fulfilled, (state, action) => {
                state.dispatcherList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getDispatcherAdminPanel.rejected, (state, action) => {
                state.dispatcherList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getDriversAdminPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getDriversAdminPanel.fulfilled, (state, action) => {
                state.driversList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getDriversAdminPanel.rejected, (state, action) => {
                state.driversList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getMedicalOrgPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getMedicalOrgPanel.fulfilled, (state, action) => {
                state.medicalList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getMedicalOrgPanel.rejected, (state, action) => {
                state.medicalList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getMedicalAdminPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getMedicalAdminPanel.fulfilled, (state, action) => {
                state.medicalList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getMedicalAdminPanel.rejected, (state, action) => {
                state.medicalList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getRoles.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                const translatedRoles = action.payload.map((role) => {
                    return {
                        value: role.value,
                        text: TranslatedRoles[role.text as keyof typeof TranslatedRoles],
                    };
                });
                state.roleList = [...translatedRoles];
                state.loadingStatus = false;
            })
            .addCase(getRoles.rejected, (state, action) => {
                state.roleList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getSupervisors.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getSupervisors.fulfilled, (state, action) => {
                state.supervisorList = action.payload;
                state.loadingStatus = false;
            })
            .addCase(getSupervisors.rejected, (state, action) => {
                state.supervisorList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(editOrgUsers.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(editOrgUsers.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState as UsersEntityList;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(editOrgUsers.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(deleteOrgUser.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(deleteOrgUser.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState as UsersEntityList;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(deleteOrgUser.rejected, (state, action) => {
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(getWorkers.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(getWorkers.fulfilled, (state, action) => {
                state.workersList = [
                    {
                        text: "-",
                        value: "",
                    },
                    ...action.payload,
                ];
                state.loadingStatus = false;
            })
            .addCase(getWorkers.rejected, (state, action) => {
                state.workersList = null;
                state.loadingStatus = false;
            });
        // ========================================================
        builder
            .addCase(addNewUserOrgPanel.pending, (state, action) => {
                state.loadingStatus = true;
            })
            .addCase(addNewUserOrgPanel.fulfilled, (state, action) => {
                const tableInfo = action.payload.tableInfo;
                const updatedState = action.payload.updatedState;

                updateState(tableInfo, state, updatedState!);
                state.loadingStatus = false;
            })
            .addCase(addNewUserOrgPanel.rejected, (state, action) => {
                state.loadingStatus = false;
            });
    },
});

// экшены
const {reducer, actions} = userSlice;

export const {resetUsersSlice} = actions;

export default reducer;
