import axios from "axios";

import {
    PRETRIP,
    MEDICAL_USER_PRETRIP_CARDS,
    DRIVER_USER_PRETRIP_CARDS,
    MEDICAL_SET_COMMENT,
    MEDICAL_UPDATE_STATUS,
    USER_CARD_BY_ID,
    USER_STATISTIC,
} from "@constants/api/pretrip_api";

import {
    PretripList,
    PretripCardList,
    PretripDriverCardList,
    PreTripUsersLastCard,
    PreTripUserStatistic,
    PreTripUpdateStatus,
    PreTripUpdateComment,
} from "@app-types/preTrip.model";

class PreTripService {
    //-------------------------- PreTrip-------------------------
    async getPreTripList(searchParams: FoundationApp.SearchParams) {
        return await axios
            .get<PretripList>(PRETRIP, {
                params: {
                    ...searchParams,
                    status: searchParams.status !== '' ? searchParams.status : undefined,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async getPreTripCards(searchParams: FoundationApp.SearchParams) {
        return await axios
            .get<PretripCardList>(MEDICAL_USER_PRETRIP_CARDS, {
                params: {
                    ...searchParams,
                    status: searchParams.status !== '' ? searchParams.status : undefined,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async getPreTripDriverCards(searchParams: FoundationApp.SearchParams) {
        return await axios
            .get<PretripDriverCardList>(DRIVER_USER_PRETRIP_CARDS, {
                params: {
                    ...searchParams,
                    status: searchParams.status !== '' ? searchParams.status : undefined,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    async getUsersLastCardById(userId: string) {
        return await axios
            .get<PreTripUsersLastCard>(`${USER_CARD_BY_ID}/${userId}`)
            .then((response) => {
                return response.data;
            });
    }

    //-------------------------- PreTripHub-------------------------
    async sendStatus({id, status}: PreTripUpdateStatus) {
        return await axios.put<void>(
            MEDICAL_UPDATE_STATUS,
            {
                id,
                status,
            },
            {
                headers: {
                    "Content-Type": "application/json-patch+json",
                },
            }
        );
    }

    async sendComment({id, comment}: PreTripUpdateComment) {
        return await axios.put<void>(
            MEDICAL_SET_COMMENT,
            {
                id,
                comment,
            },
            {
                headers: {
                    "Content-Type": "application/json-patch+json",
                },
            }
        );
    }

    //-------------------------- Statistic-------------------------
    async getUserStatistic(userId: number) {
        return await axios
            .get<PreTripUserStatistic>(`${USER_STATISTIC}/${userId}`)
            .then((response) => {
                return response.data;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PreTripService();
