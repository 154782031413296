// @ts-nocheck
let IDENTITY_BACKEND = null;

if (window._env_ !== undefined) {
  IDENTITY_BACKEND = window._env_.REACT_APP_IDENTITY_API_URL;
} else {
  IDENTITY_BACKEND = process.env.REACT_APP_IDENTITY_API_URL;
}

export const LOGIN = `${IDENTITY_BACKEND}/Auth/login/`;
export const REFRESH = `${IDENTITY_BACKEND}/Auth/refresh/`;
