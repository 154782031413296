import axios from "axios";

// AdminPanel
import {
    USERS_ADMIN_PANEL,
    REMOVE_USER_ADMIN_PANEL,
    ADD_USER_ADMIN_PANEL,
    EDIT_USER_ADMIN_PANEL,
    GET_USER_PROFILE_BY_ID_FOR_EDIT_ADMIN_PANEL,
    ADD_USER_ORG_PANEL,
    JOB,
    COMPANY,
    EDIT_ORG_PANEL_USER,
} from "@constants/api/user_api";

// Profile
import {
    CURRENT_PROFILE,
    // ADD_NEW_USER,
    EDIT_PROFILE,
    GET_USER_PROFILE_FOR_EDIT,
    GET_USER_PROFILE_BY_ID_FOR_EDIT,
    REMOVE_USER,
    ADD_PHOTO,
    DELETE_PHOTO,
} from "@constants/api/user_api";

// Company
import {COMPANY_LIST} from "@constants/api/user_api";

// Jobs
import {JOBS_LIST} from "@constants/api/user_api";

// Role
import {USERS_ROLES} from "@constants/api/user_api";

// Users
import {USER_ADMIN, USER_SUPERVISOR} from "@constants/api/user_api";

import {
    USER_DRIVER,
    USER_DRIVER_ADMIN,
    USER_MEDICAL,
    USER_MEDICAL_ADMIN,
    USER_DISPATCHER,
    USER_DISPATCHER_ADMIN,
} from "@constants/api/user_api";

// Workers
import {WORKERS} from "@constants/api/user_api";

import {
    UsersEntityList,
    AllUsersAdminPanelList,
    AddNewUserParamsAdminPanel,
    ProfileByIdForEditAdminPanel,
    EditUserParamsAdminPanel,
    DriverUsersOrgPanelList,
    EditUserParamsOrgPanel,
    AddNewUserOrgPanel,
    JobsCreateParams,
    JobsEditParams,
    CompanyEditParams,
    CompainesList,
} from "@app-types/users.model";

import {
    ProfileInfo,
    // ProfileAddNewUser,
    ProfileEditArguments,
    CurrentProfile,
} from "@app-types/profile.model";

class UserService {
    //---------------------------- Admin----------------------------

    /**
     * Получение только Админов в Редактировании пользователей
     */
    async getAdmins({
                        pageNumber = 0,
                        pageSize = 0,
                        searchString = "",
                        dateBegin = null,
                        dateEnd = null,
                    }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_ADMIN, {
                params: {
                    pageNumber,
                    pageSize,
                    searchString,
                    dateBegin,
                    dateEnd,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //-----------------------------AdminPanel----------------------------

    /**
     * Получение ВСЕХ пользователей
     */
    async getUsers(searchParams: FoundationApp.SearchParams) {
        return await axios
            .get<AllUsersAdminPanelList>(USERS_ADMIN_PANEL, {
                params: {
                    ...searchParams,
                    role: searchParams.role ? searchParams.role : undefined,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Добавление новых пользователей в АДМИНСКОЙ ПАНЕЛИ
     */
    async addNewUserAdminPanel(newUserInfo: AddNewUserParamsAdminPanel) {
        const {tableInfo, ...rest} = newUserInfo;

        return await axios.post<void>(
            ADD_USER_ADMIN_PANEL,
            {
                ...rest,
            },
            {
                headers: {
                    "Content-Type": "application/json-patch+json",
                },
            }
        );
    }

    /**
     * Редактирование собственного профиля в ProfileSettings АДМИНОМ
     */
    async editAdminPanelUser(editUserInfo: EditUserParamsAdminPanel) {
        const {tableInfo, ...rest} = editUserInfo;
        return await axios.put<void>(EDIT_USER_ADMIN_PANEL, {
            ...rest,
        });
    }

    /**
     * Удаление пользователя в АДМИНСКОЙ ПАНЕЛИ
     */
    async deleteUserAdminPanel(userId: string) {
        return await axios.put(`${REMOVE_USER_ADMIN_PANEL}/${userId}/`);
    }

    /**
     * Получение профиля пользователя по ID для редактирования в АДМИНСКОЙ ПАНЕЛИ
     */
    async getProfileByIdAdminPanel(userId: string) {
        return await axios
            .get<ProfileByIdForEditAdminPanel>(
                `${GET_USER_PROFILE_BY_ID_FOR_EDIT_ADMIN_PANEL}/${userId}/`
            )
            .then((response) => {
                return response.data;
            });
    }

    //-----------------------------Company----------------------------
    /**
     * Получение всех компаний (с флагом удален) для админа
     */
    async getCompaines(searchArguments: FoundationApp.SearchParams) {
        return await axios
            .get<CompainesList>(COMPANY, {
                params: {...searchArguments},
            })
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Получение списка (selectList) компании
     */
    async getCompanyList() {
        return await axios
            .get<FoundationApp.SelectItems>(COMPANY_LIST)
            .then((response) => {
                return response.data.items;
            });
    }

    /**
     * Создание компании
     */
    async createCompany(name: string) {
        return await axios.post<void>(COMPANY, {
            name,
        });
    }

    /**
     * Редактирование компании
     */
    async editCompany(companyData: CompanyEditParams) {
        return await axios.put<void>(COMPANY, {
            ...companyData,
        });
    }

    /**
     * Удаление компании
     */
    async deleteCompany(id: number) {
        return await axios.put<void>(`${COMPANY}${id}/`);
    }

    //-----------------------------Company----------------------------

    /**
     * Создание новой должности
     */
    async createJobTitle(jobTitleData: JobsCreateParams) {
        return await axios.post<void>(`${JOB}`, jobTitleData);
    }

    /**
     * Редактирование должности
     */
    async editJobTitle(jobTitleData: JobsEditParams) {
        return await axios.put<void>(`${JOB}`, jobTitleData);
    }

    /**
     * Удаление должности
     */
    async deleteJobTitle(id: number) {
        return await axios.delete<void>(`${JOB}${id}/`);
    }

    /**
     * Получение списка должностей для админа
     */
    async getJobsTitleAdmin(role: string, company: number) {
        return await axios
            .get<FoundationApp.SelectItems>(`${JOBS_LIST}/${company}/${role}/`)
            .then((response) => {
                return response.data.items;
            });
    }

    /**
     * Получение списка должностей для supervisor
     */
    async getJobsTitleSupervisor(role: string) {
        return await axios
            .get<FoundationApp.SelectItems>(`${JOBS_LIST}/${role}/`)
            .then((response) => {
                return response.data.items;
            });
    }

    //-----------------------------Dispatcher-------------------------------------
    /**
     * Получение списка водителей вне админской учетки (полная информация)
     */
    async getDispatcherOrgPanel({
                                    pageNumber = 0,
                                    pageSize = 0,
                                    searchString = "",
                                    dateBegin = null,
                                    dateEnd = null,
                                }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_DISPATCHER, {
                params: {searchString, dateBegin, dateEnd, pageSize, pageNumber},
            })
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Получение списка водителей в АДМИНСКОЙ ПАНЕЛИ(полная информация) (пока не используется)
     */
    async getDispatcherAdminPanel({
                                      pageNumber = 0,
                                      pageSize = 0,
                                      searchString = "",
                                      dateBegin = null,
                                      dateEnd = null,
                                  }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_DISPATCHER_ADMIN, {
                params: {searchString, dateBegin, dateEnd, pageSize, pageNumber},
            })
            .then((response) => {
                return response.data;
            });
    }

    //-----------------------------Driver-------------------------------------
    /**
     * Получение списка водителей вне админской учетки (полная информация)
     */
    async getDriversOrgPanel({
                                 pageNumber = 0,
                                 pageSize = 0,
                                 searchString = "",
                                 dateBegin = null,
                                 dateEnd = null,
                             }: FoundationApp.SearchParams) {
        return await axios
            .get<DriverUsersOrgPanelList>(USER_DRIVER, {
                params: {searchString, dateBegin, dateEnd, pageSize, pageNumber},
            })
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Получение списка водителей в АДМИНСКОЙ ПАНЕЛИ(полная информация) (пока не используется)
     */
    async getDriversAdminPanel({
                                   pageNumber = 0,
                                   pageSize = 0,
                                   searchString = "",
                                   dateBegin = null,
                                   dateEnd = null,
                               }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_DRIVER_ADMIN, {
                params: {searchString, dateBegin, dateEnd, pageSize, pageNumber},
            })
            .then((response) => {
                return response.data;
            });
    }

    //-----------------------------Medical----------------------------
    /**
     * Получение списка медиков вне админской учетки
     */
    async getMedicalOrgPanel({
                                 pageNumber = 0,
                                 pageSize = 0,
                                 searchString = "",
                                 dateBegin = null,
                                 dateEnd = null,
                             }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_MEDICAL, {
                params: {
                    searchString,
                    dateBegin,
                    dateEnd,
                    pageSize,
                    pageNumber,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Получение списка медиков в АДМИНСКОЙ ПАНЕЛИ (пока не используется)
     */
    async getMedicalAdminPanel({
                                   pageNumber = 0,
                                   pageSize = 0,
                                   searchString = "",
                                   dateBegin = null,
                                   dateEnd = null,
                               }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_MEDICAL_ADMIN, {
                params: {
                    searchString,
                    dateBegin,
                    dateEnd,
                    pageSize,
                    pageNumber,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //-----------------------------Profile----------------------------

    /**
     * Получение профиля текущего авторизованного пользователя
     */
    async getProfileInfo() {
        return await axios.get<ProfileInfo>(CURRENT_PROFILE).then((response) => {
            return response.data;
        });
    }

    /**
     * Добавление новых пользователей в ОРГ ПАНЕЛИ
     *
     * */
    async addNewUserOrgPanel(newUserInfo: AddNewUserOrgPanel) {
        const {tableInfo, ...rest} = newUserInfo;
        const resp = await axios.post(
            ADD_USER_ORG_PANEL,
            {...rest},
            {
                headers: {
                    "Content-Type": "application/json-patch+json",
                },
            }
        );

        if (resp.status === 204) {
            throw new Error("204");
        } else {
            return resp.data;
        }
    }

    /**
     * Редактирование собственного профиля в ProfileSettings
     *
     * Имеет одни и те же параметры и URL как для editOrgUsers
     */
    async editProfile(editProfileInfo: ProfileEditArguments) {
        return await axios.put<void>(EDIT_PROFILE, {
            ...editProfileInfo,
        });
    }

    /**
     * Получение профиля для редактирования текущего пользователя
     */
    async getProfileForEdit() {
        return await axios
            .get<CurrentProfile>(GET_USER_PROFILE_FOR_EDIT)
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Удаление пользователя вне админской учетки
     */
    async deleteOrgUser(userId: string) {
        return await axios.put<void>(`${REMOVE_USER}/${userId}`);
    }

    /**
     * Получение профиля для редактирования по ID орг панель
     */
    async getProfileByIdOrgPanel(userId: string) {
        return await axios
            .get<CurrentProfile>(`${GET_USER_PROFILE_BY_ID_FOR_EDIT}/${userId}/`)
            .then((response) => {
                return response.data;
            });
    }

    /**
     * Редактирование юзеров в орг панели
     *
     * * Имеет одни и те же параметры и URL как для editProfile
     */
    async editOrgUsers(editUserInfo: EditUserParamsOrgPanel) {
        const {tableInfo, ...rest} = editUserInfo;
        return await axios.put(`${EDIT_ORG_PANEL_USER}/${editUserInfo.id}/`, {
            ...rest,
        });
    }

    /**
     * Добавление фото
     */
    async addPhoto(photo: FormData) {
        return await axios.put<void>(ADD_PHOTO, photo, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    /**
     * Удаление фото
     */
    async deletePhoto() {
        return await axios.delete<void>(DELETE_PHOTO);
    }

    //--------------------------------Role----------------------------
    /**
     * Получение списка типов ролей
     */
    async getRoles() {
        return await axios
            .get<FoundationApp.SelectItems>(USERS_ROLES)
            .then((response) => {
                return response.data.items;
            });
    }

    //--------------------------------- Supervisor ----------------------------
    /**
     * Получение руководителей в Редактировании пользователей
     */
    async getSupervisors({
                             pageNumber = 0,
                             pageSize = 0,
                             searchString = "",
                             dateBegin = null,
                             dateEnd = null,
                         }: FoundationApp.SearchParams) {
        return await axios
            .get<UsersEntityList>(USER_SUPERVISOR, {
                params: {
                    pageNumber,
                    pageSize,
                    searchString,
                    dateBegin,
                    dateEnd,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //---------------------------------Worker----------------------------
    /**
     * Получение списка водителей (ФИО и id)
     */
    async getWorkers() {
        return await axios
            .get<FoundationApp.SelectItems>(WORKERS)
            .then((response) => {
                return response.data.items;
            });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();
