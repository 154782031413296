import qs from "qs";
import axios from "axios";
import TokenService from "./TokenService";
import {LOGIN, REFRESH} from "@constants/api/auth_api";

import {TokenInfo} from "@app-types/auth.model";

class AuthService {
    async login(email: string, password: string) {
        const response = await axios.post<TokenInfo>(
            LOGIN,
            qs.stringify({
                client_id: "react",
                grant_type: "password",
                username: email,
                password,
            }),
            {withCredentials: true}
        );

        return response.data;
    }

    async logout() {
        // TokenService.removeRefreshToken();
        TokenService.removeSession();
    }

    async refreshToken() {
        const response = await axios.post(
            REFRESH,
            qs.stringify({
              client_id: "react",
              grant_type: "refresh_token",
              // refresh_token: TokenService.getRefreshToken(),
            }),
            {
                headers: {
                    "content-type": "application/x-www-form-urlencoded",
                },
                withCredentials: true,
            }
        );

        return response.data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
